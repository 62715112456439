<script setup lang="ts">
import { getSpacingClassesForBlok } from '@/utils/storyblok';
import type { ContentCarouselStoryblok } from '@/storyblok-types';

withDefaults(defineProps<{
  blok: ContentCarouselStoryblok;
}>(), {  })

const hasDarkBackground = computed(() => (__props.blok.backgroundColor?.value
  ? isDarkColor(__props.blok.backgroundColor?.value)
  : false));

const spacingClasses = computed(() => getSpacingClassesForBlok(__props.blok));

const cta = computed(() => __props.blok.cta?.[0]);
</script>

<template>
  <div
    v-editable="blok"
    :class="[hasDarkBackground ? 'text-white' : 'text-dark', spacingClasses]"
    :style="{ backgroundColor: blok.backgroundColor?.value }"
  >
    <div class="max-w-9xl container relative overflow-hidden">
      <div
        class="grid grid-cols-1 items-center gap-4"
        :class="{
          'md:grid-cols-[2fr_2fr] ml:grid-cols-[1fr_2fr] ml:gap-12': !blok.boxed,
          '-mx-6 rounded-xl bg-dark p-6 pb-0 text-white 2xl:overflow-hidden': blok.boxed,
        }"
      >
        <div class="flex flex-wrap items-end justify-between gap-4">
          <div>
            <h3
              v-if="blok.headline"
              class="my-0 font-black"
              :class="{
                'text-3xl !leading-tight md:text-5xl': !blok.boxed,
                'text-xl md:text-2xl': blok.boxed,
                'uppercase': blok.headlineUppercased,
              }"
              :style="{ color: blok.headlineColor?.value }"
            >
              <FormattedMarkdown :content="blok.headline" />
            </h3>
            <p
              v-if="blok.description"
              class="justify mb-0 mt-4 flex text-lg font-medium opacity-80"
            >
              <FormattedMarkdown :content="blok.description" />
            </p>

            <div
              v-if="cta"
              class="md:mt-4"
            >
              <CTABlock
                class="-mx-4 md:-mx-5 lg:-mx-10"
                :blok="cta"
              />
            </div>
          </div>
        </div>

        <div
          class="flex-1 overflow-hidden"
          :class="{'-mr-6 md:-mr-7 lg:-mr-12 2xl:-mr-8': blok.boxed,
                   '-mr-6 md:-mr-7 lg:-mr-12 2xl:-mr-0': !blok.boxed,
          }"
        >
          <UiScroller
            button-style-dark
            never-hide-buttons
            class="product-carousel-scroller -mx-2"
            position-button-left="left-8"
            position-button-right="right-8"
            :background-color="blok.backgroundColor?.value"
            :scroll-distance="250"
          >
            <template #scrollerContent>
              <div
                class="flex gap-4 px-2 pb-8 pt-4"
                :class="{'pr-8': blok.boxed}"
              >
                <ContentCarouselItem
                  v-for="item of blok.items"
                  :key="item._uid"
                  :item="item"
                />
              </div>
            </template>
          </UiScroller>
        </div>
      </div>
    </div>
  </div>
</template>
